.custom-input-field-control{
  @include input-field;
  @include text-medium-thin;
}
.custom-input-field-control:focus{
  @include input-focus;
}
.custom-input-field-control.readonly{
  @include input-read-Only;
}
.custom-input-field-control:disabled{
  @include input-disable;
}
.custom-input-field-control.invalid{
  @include input-invalid;
}
.custom-input-label{
  @include input-label;
  @include text-medium-normal;
}
.custom-input-field-control.large-input{
  height: 120px;
  resize: none;
}


// TODO: check the color
.active{
  color: $inputActiveColor;
  @include text-medium-normal;
}

.custom-input-field-error{
  display: flex;
  align-items: center;
  @include input-error;
  @include text-small-normal;
  img{
    margin-right: 4px;
  }
}

.dropdown-item{
  @include text-small-normal;
  &.selected, &:hover{
    @include text-small-bold;
  }
}
