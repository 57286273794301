@import "~amdocs-core/assets/scss/core";
@import "~amdocs-core/assets/scss/scroll";
@import "shared-variables";
@import "map-icons";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker";
@import "~amdocs-core/assets/scss/datepicker";
@import '~ngx-toastr/toastr';

.mimic-wrapper {
  z-index: 10;
  input {
    width: 60px;
  }
}

.lds-roller {
  z-index: $fullPageLoaderIndex;
}

.amdocs-user-notify-alert-wrapper {
  z-index: $userAlertIndex;
}
.ProgressiveWebApp-notification {
  z-index: $iosAddToHomeIndex;
}

::ng-deep{
  lib-amdocs-app-loader{
    border: red 2px solid;
    z-index: 99;
  }
}
body {
  position: relative;
  color: $colorD1;
}
body.show-splash {
  //background-color: $colorP1;
  background-image: url("/assets/icons/splash-mobile.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.modal{
  display: flex !important;
  justify-content: center !important;
  .modal-content{
    padding: 0;
  }
}

.pointer {
  cursor: pointer;
}

.full-height {
  height: 100%;
}

.leaflet-popup {
  margin-bottom: 15px;
  margin-left: 8px;
  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-popup-content-wrapper {
    .leaflet-popup-content {
      text-align: center;
      max-width: 100px;
      width: auto;
      margin: 0;
      padding: 5px;
    }
  }
}

.leaflet-popup-tip-container {
  .leaflet-popup-tip {

  }
}


@media only screen and (min-width: $desktopSize) {
  body.show-splash {
    background-image: url("/assets/icons/splash-web.png");
    background-position: unset;
  }
}

.bs-datepicker-body table td span.is-other-month{
  display:none;
}
.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month):not(.selected){
  border: 1px solid $colorD9;
  color:$colorD9;
}
.bs-datepicker-body table td span.disabled{
  color:$colorD8;
}
