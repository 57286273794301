@import "variables";

.modal-backdrop.show {
  background-color: $backdrop;
  opacity: 1;
}

.modal-dialog {
  box-shadow: 0 2px 15px 0 $modalShadow;

  border: none;
  margin: 90px 20px auto 20px;
  max-width: 100%;
}

.modal-content {
  text-align: center;
  padding: 32px 0;
  border-radius: 10px;
  .close-popup-button {
    top: 26px;
    right: 32px;
    position: absolute;
    z-index: 10;
    img {
      width: 22px;
      height: 22px;
    }
  }
  .modal-body {
    padding: 0;
  }
}
