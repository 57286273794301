@import "variables";
@import "mixin";

.section-header {
  font-size: 50px;
  background-color: $mainTextColor;
  color: $white;
  padding: 40px;
  font-weight: $weightMedium;
}
.btn {

  @include special-s2;
  color: $white;
  border: none;

  &:active, &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: $white;
  }

  &.btn-large {
    border-radius: 27px;
    padding: 14px 24px;
    width: 100%;
    &.icon-button{
      padding: 8px 24px 8px 16px;
    }
  }

  &.btn-medium {
    border-radius: 20px;
    padding: 10px 24px;
    &.icon-button{
      padding: 10px 24px 10px 16px;
    }
  }

  &.btn-small {
    border-radius: 20px;
    padding: 8px 24px;
    @include special-s1;
    &.icon-button{
      padding: 6px 20px 6px 12px;
    }
  }

  &.btn-tiny {
    @include special-s1;
    border-radius: 12px;
    padding: 4px 12px;
    &.icon-button{
      padding: 2px 12px 2px 8px;
    }
  }

  &.btn-text, &.btn-text.disabled, &.btn-text:disabled {
    @include text-small-bold;
    display: flex;
    align-items: flex-end;
    padding: 0;
    background-color: transparent;
    color: $colorP2;
    text-transform: none;

    &:disabled, &.disabled {
      background-color: transparent;
      color: $colorP2;
    }
  }

  &.outline {
    box-shadow: inset 0px 0px 0px 2px $colorP2;
    color: $colorP2;
    &.dark-bg{
      //color: white;
      color: $colorP4;

    }
  }

  &.dark {
    background-color: $colorD1;

    &:hover {
      background-color: $colorD2;
    }
  }

  &.secondary-dark {
    border: 1px solid $white;
    &:hover {
      background-color: $colorD7;
    }
  }

  &.primary {
    background-color: $colorP2;

    &:hover {
      background-color: $colorD2;
    }
  }

  &.secondary {
    color: $colorP2;
    background: $white;
    border: 1px solid $colorP2;

    &:hover {
      color: $colorP2;
      background-color: $colorD7;
    }
  }


  &.reject {
    background-color: $pinkRed;
    font-weight: $weightRegular;
    &:hover {
      background-color: $pinkRed;
      opacity: 0.6;
    }
  }


  &.accept {
    background-color: $accept;
    font-weight: $weightRegular;
    &:hover {
      background-color: $green;
    }
  }

  &.white, &.white:hover {
    color: $colorP2;
    background-color: $white;
  }

  &:disabled, &.disabled {
    opacity: 1;
    color: $colorD4;
    background-color: $colorD5;
    &:hover {
      opacity: 1;
      color: $colorD4;
      background-color: $colorD5;
    }
  }

  &:active, &:focus {
    outline: none;
  }

  &.attachment {
    text-decoration: underline;
    &:before {
      height: 20px;
      width: 20px;
      background-image: url("/assets/icons/attachment.svg");
      margin-right: 4px;
      content: "";
    }
  }

  &.expand {
    text-decoration: underline;
    &:after {
      height: 20px;
      width: 20px;
      margin-left: 4px;
      content: "";
      background-image: url("/assets/icons/arrow-down.svg");
      background-repeat: no-repeat;
      position: relative;
      top: 6px;
      left: 3px;
    }

    &.open {
      &:after {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
  }

  &.filter {
    &:after {
      height: 5px;
      width: 8px;
      background-image: url("/assets/icons/dropdown-purple-icon.svg");
      margin-bottom: 7px;
      content: "";
      margin-left: 5px;
    }
    &.open {
      &:after {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
  }

  &.icon-button {
    .button-icon {
      margin-right: 8px;
      &.icon-after{
        margin-right: 0;
        margin-left: 9px;
      }
    }
    &.icon-after {
      padding: 10px 16px 10px 16px;
    }
  }
}
