@import "variables";
html {
  height: 100%;
}
body {
  min-height: 100%;
  height: 100%;
  margin:0;
  padding: 0;
  //overflow: hidden;
}

.page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .content-wrapper {
    overflow: auto;
  }
}

@media only screen and (min-width: $desktopSize) {
  html {
    height: auto;
  }
  body {
    min-height: inherit;
    height: inherit;;
    margin:0;
    padding: 0;
    overflow: auto;
  }
}
