@import "~ngx-bootstrap/datepicker/bs-datepicker";
@import "./variables";
@import "./mixin";

.amdocs-date-picker-wrapper {
  width: 270px;
  @media (max-width: $desktopSize) {
    width: 100%;
  }

  //bs-datepicker-inline {
  bs-datepicker-inline-container {
    @media (max-width: $desktopSize) {
      width: 100%;
    }
  }
  //}
}
.bs-datepicker {
  box-shadow: none;
  .bs-datepicker-container {
    @media (max-width: $desktopSize) {
      width: 100%;
    }
    padding: 0;

    .bs-datepicker-head {
      background-color: $white;

      .previous, .next {
        background-image: url("/assets/icons/arrow-right.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;

        span {
          display: none;
        }
      }

      .previous {
        transform: rotate(180deg);
      }

      .current {
        @include text-medium-bold;
        color: $colorD1;

        &:hover {
          background-color: $white;
        }
      }
    }

    .is-other-month {
      //display: none;
    }

    .bs-datepicker-body {
      padding: 0;
      border: none;

      .today {
        color: $colorP2;
        font-weight: bold;
        @include text-small-bold;
        line-height: 32px;
      }

      .selected {
        @include text-small-normal;
        line-height: 32px;
        color: $white;
        background-color: $colorP3;
      }

      table th{
        color: $colorP3;
      }
    }
  }
}
