@import "./variables";
$scrollWidth: 6px;
/* width */
::-webkit-scrollbar {
  width: $scrollWidth;
}
/* Track */
::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: $scrollWidth;
  border-radius: 3px;
  background-color: $colorD5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $colorD4;
}
