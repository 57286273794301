@import "variables";

@mixin label-h0 {
  font-weight: $weightRegular;
  font-size: 36px;
  line-height: 52px;
}

@mixin label-h02 {
  font-weight: $weightMedium;
  font-size: 28px;
  line-height: 40px;
}

@mixin label-h1 {
  font-weight: $weightMedium;
  font-size: 24px;
  line-height: 32px;
}

@mixin label-h3 {
  font-weight: $weightRegular;
  font-size: 16px;
  line-height: 20px;
}

@mixin label-h4 {
  font-weight: $weightBold;
  font-size: 16px;
  line-height: 20px;
}

@mixin label-h5 {
  font-weight: $weightBold;
  font-size: 12px;
  line-height: 20px;
}


// medium
@mixin text-medium-thin {
  font-weight: $weightRegular;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-medium-normal {
  font-weight: $weightMedium;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-medium-bold {
  font-weight: $weightBold;
  font-size: 14px;
  line-height: 20px;
}

// small
@mixin text-small-thin {
  font-weight: $weightRegular;
  font-size: 12px;
  line-height: 20px;
}

@mixin text-small-normal {
  font-weight: $weightMedium;
  font-size: 12px;
  line-height: 20px;
}

@mixin text-small-bold {
  font-weight: $weightBold;
  font-size: 12px;
  line-height: 20px;
}

// tiny
@mixin text-tiny-thin {
  font-weight: $weightRegular;
  font-size: 11px;
  line-height: 16px;
}

@mixin text-tiny-normal {
  font-weight: $weightMedium;
  font-size: 11px;
  line-height: 16px;
}

// special
@mixin special-s1 {
  font-weight: $weightBold;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}

@mixin special-s2 {
  font-weight: $weightBold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}

@mixin input-field {
  -webkit-appearance: none;
  border-radius: 8px;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
  border: 2px $inputBorderColor solid;
  padding: 12px;
  width: 100%;
  color: $inputColor;
  background-color: $white;
  outline: none;
  height: 44px;
}


@mixin input-label {
  margin-bottom: 8px;
  color: $inputLabelColor;
  width: 100%;
}

@mixin active-input {
  border: red 2px solid;
}

@mixin input-error {
  margin-top: 4px;
  min-height: 20px;
  color: $pinkRed;

  //&:before {
  //  background-image: url("/assets/icons/error.svg");
  //  content: "";
  //  display: inline-block;
  //  height: 20px;
  //  width: 20px;
  //  background-size: 100%;
  //  margin-right: 4px;
  //}
  //border: red 2px solid;
}

@mixin input-focus{
  color: $inputActiveColor;
  border-color: $inputFocusedBorderColor;
}

@mixin input-read-Only{
  background-color: $inputDisableBgColor;
  color: $inputActiveColor;
  pointer-events: none;
}
@mixin input-disable{
  background-color: $inputDisableBgColor;
  color: $inputDisableColor;
}
@mixin input-invalid{
  border-color: $inputErrorColor;
}
