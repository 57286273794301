@import "mixin";
@import "variables";

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $weightLight;
  src: url("/assets/fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $weightRegular;
  src: url("/assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $weightMedium;
  src: url("/assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $weightBold;
  src: url("/assets/fonts/Montserrat-Bold.ttf");
}

// HEADING

.label-h0 {
  @include label-h0;
}

.label-h02 {
  @include label-h02;
}

.label-h1 {
  @include label-h1;
}

.label-h3 {
  @include label-h3;
}

.label-h4{
  @include label-h4;
}

.label-h5 {
  @include label-h5;
}

// TEXT

// medium
.text-medium-thin {
  @include text-medium-thin;
}
.text-medium-normal {
  @include text-medium-normal;
}
.text-medium-bold {
  @include text-medium-bold;
}

// small
.text-small-thin {
  @include text-small-thin;
}
.text-small-normal {
  @include text-small-normal;
}
.text-small-bold {
  @include text-small-bold;
}

// tiny
.text-tiny-thin {
  @include text-tiny-thin;
}
.text-tiny-normal {
  @include text-tiny-normal;
}

// SPECIAL
.special-s1 {
  @include special-s1;
}
.special-s2 {
  @include special-s2;
}

.text-color-primary {
  color: $primaryTextColor;
}

.text-color-secondary {
  color: $secondaryTextColor;
}
