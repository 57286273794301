@import "~bootstrap/scss/bootstrap";
@import "~swiper/swiper-bundle";
@import "button";
@import "popup";
@import "checkbox";
@import "fonts";
@import "input";
@import "radio";
@import "textarea";
@import "basic-elements";
@import "mobile-full-height";

body * {
  font-size: 14px;
  font-family: $defaultFont;
}

