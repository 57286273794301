$slideAnimationDuration: 1s;
$desktopSize: 1025px;
$defaultFont: 'Montserrat';

$weightLight: 100;
$weightRegular: 400;
$weightMedium: 500;
$weightBold: 700;

// SPACIAL

$itemBoxShadow: rgba(0,0,0,0.1);
$modalShadow: rgba(127,130,215,0.16);
$backdrop: rgba(34,33,46,0.9);

// COLORS
$white: #FFFFFF;
$colorP1: #C63F42;
$colorP2: #22212E;
$colorP3: #F05C62;
$colorP4: #EE9497;
$colorP5: #F1F1F4;
$colorP6: #F6E8E8;

$colorD1: #22212E;
$colorD2: #343647;
$colorD3: #82889C;
$colorD4: #A3A3AE;
$colorD5: #C9C9D3;
$colorD6: #E8E9ED;
$colorD7: #F6F7FC;
$colorD8: #D3D3D3;
$colorD9:#008000;

$lightGreen: #DCF6EB;
$lightYellow: #FFF4CE;
$lightRed: #FFE5E6;
$yellow: #F5A623;

// ALERTS
$pinkRed: #E60001;
$green: #9ED62B;
$accept: #48A800;

// GRADIENTS
$purpleGradientColor: linear-gradient(310.02deg, #DF6469 0%, #CC377C 100%);
$pinkYellowGradientColor: linear-gradient(225deg, #EC617C 0%, #FABE90 100%);


// COLORS VALUES

$errorColor: $pinkRed;
$successColor: $green;

$mainTextColor: $colorD1;
$primaryTextColor: $colorP2;
$secondaryTextColor: $colorD3;
$labelTextColor: $colorD2;

$disableTextColor: $colorD5;
$disableActionColor: $colorD5;
$disableBorderColor: $colorD6;

$headerBackgroundColor: $colorP2;
$backgroundOnWhiteColor: $colorP6;
$backgroundColor: $white;

$selectedItemColor: $colorP3;
$buttonsColor: $colorP2;
$checkboxBG: $colorP5;
$iconsColor: $colorP3;

$separatorColor: $colorP5;
$inputBorderColor: $colorP5;
$inputColor: $colorD1;
$inputActiveColor: $colorD1;
$inputLabelColor: $secondaryTextColor;
$inputDisableBgColor: $colorD7;
$inputDisableColor: $colorD4;
$inputFocusedBorderColor: $colorP4;
$inputErrorColor: $pinkRed;
$dropdown-selected: $colorP2;


// Z-INDEXES

$newVersionAlert: 3000;
