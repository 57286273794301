@import "./shared-variables";
.map-icon {
  background-size: contain;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  &.admin {
    // inside map icons
    &.allocated-assigned {
      background-image: url("/assets/icons/admin-seats/allocated-assigned.svg");
    }
    &.unallocated-assigned {
      background-image: url("/assets/icons/admin-seats/unallocated-assigned.svg");
    }
    &.unallocated-selected {
      background-image: url("/assets/icons/admin-seats/unallocated-selected.svg");
    }
    &.allocated-assigned-selected {
      background-image: url("/assets/icons/admin-seats/allocated-assigned-selected.svg");
    }
    &.unallocated-assigned-selected {
      background-image: url("/assets/icons/admin-seats/unallocated-assigned-selected.svg");
    }
    &.unallocated-unbookable {
      background-image: url("/assets/icons/admin-seats/unallocated-unbookable.svg");
    }
    &.unallocated-unbookable-selected {
      background-image: url("/assets/icons/admin-seats/unallocated-unbookable-selected.svg");
    }
    &.allocated {
      background-image: url("/assets/icons/admin-seats/allocated.svg");
    }
    &.allocated-selected {
      background-image: url("/assets/icons/admin-seats/allocated-selected.svg");
    }
    &.allocated-unbookable {
      background-image: url("/assets/icons/admin-seats/allocated-unbookable.svg");
    }
    &.allocated-unbookable-selected {
      background-image: url("/assets/icons/admin-seats/allocated-unbookable-selected.svg");
    }
    &.selected {
      background-image: url("/assets/icons/admin-seats/selected.svg");
    }
    &.focused{
      height: 32px !important;
      width: 32px !important;
      margin-left: -7px !important;
      margin-top: -7px !important;
    }
    &.unallocated {
      background-image: url("/assets/icons/admin-seats/unallocated.svg");
    }
    &.problematic {
      background-image: url("/assets/icons/admin-seats/problematic.svg");
    }

    // map legends icons
    &.allocated-unit {
      background-image: url("/assets/icons/admin-seats/allocated-unit.svg");
    }
    &.unbookable-seat {
      background-image: url("/assets/icons/admin-seats/unbookable-seat.svg");
    }
    &.reserved-seat {
      background-image: url("/assets/icons/admin-seats/reserved-seat.svg");
    }
  }
  &.user-icon {
    &.my-booked {
      background-image: url("/assets/icons/user-seats/my-booked.svg");
    }
    &.fav-bookable {
      background-image: url("/assets/icons/user-seats/fav-bookable.svg");
    }
    &.fav-unbookable {
      background-image: url("/assets/icons/user-seats/fav-unbookable.svg");
    }
    &.friend-seat {
      background-image: url("/assets/icons/user-seats/friend-seat.svg");
    }
    &.bookable {
      background-image: url("/assets/icons/user-seats/bookable.svg");
    }
    &.bookable-selected {
      background-image: url("/assets/icons/user-seats/bookable-selected.svg");
    }
    &.bookable-amenities {
      background-image: url("/assets/icons/user-seats/bookable-amenities.svg");
    }
    &.bookable-selected-amenities {
      background-image: url("/assets/icons/user-seats/bookable-selected-amenities.svg");
    }
    &.unbookable {
      background-image: url("/assets/icons/user-seats/unbookable.svg");
    }
    &.unbookable-selected-legend {
      background-image: url("/assets/icons/user-seats/unbookable-selected-legend.svg");
    }
    &.unbookable-selected {
      background-image: url("/assets/icons/user-seats/unbookable-selected.svg");
    }
    &.focused{
      height: 32px !important;
      width: 32px !important;
      margin-left: -7px !important;
      margin-top: -7px !important;
    }
  }
}
